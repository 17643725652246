:where(body[data-setel-page]) .slick-slider, :host([data-setel-widget]) .slick-slider, setel-widget-reach-portal:not(#\#) .slick-slider {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

:where(body[data-setel-page]) .slick-list, :host([data-setel-widget]) .slick-list, setel-widget-reach-portal:not(#\#) .slick-list, :where(body[data-setel-page]) .slick-slider, :host([data-setel-widget]) .slick-slider, setel-widget-reach-portal:not(#\#) .slick-slider {
  display: block;
  position: relative;
}

:where(body[data-setel-page]) .slick-list, :host([data-setel-widget]) .slick-list, setel-widget-reach-portal:not(#\#) .slick-list {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

:where(body[data-setel-page]) .slick-list:focus, :host([data-setel-widget]) .slick-list:focus, setel-widget-reach-portal:not(#\#) .slick-list:focus {
  outline: none;
}

:where(body[data-setel-page]) .slick-list.dragging, :host([data-setel-widget]) .slick-list.dragging, setel-widget-reach-portal:not(#\#) .slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

:where(body[data-setel-page]) .slick-slider .slick-list, :host([data-setel-widget]) .slick-slider .slick-list, setel-widget-reach-portal:not(#\#) .slick-slider .slick-list, :where(body[data-setel-page]) .slick-slider .slick-track, :host([data-setel-widget]) .slick-slider .slick-track, setel-widget-reach-portal:not(#\#) .slick-slider .slick-track {
  transform: translateZ(0);
}

:where(body[data-setel-page]) .slick-track, :host([data-setel-widget]) .slick-track, setel-widget-reach-portal:not(#\#) .slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

:where(body[data-setel-page]) .slick-track:after, :host([data-setel-widget]) .slick-track:after, setel-widget-reach-portal:not(#\#) .slick-track:after, :where(body[data-setel-page]) .slick-track:before, :host([data-setel-widget]) .slick-track:before, setel-widget-reach-portal:not(#\#) .slick-track:before {
  content: "";
  display: table;
}

:where(body[data-setel-page]) .slick-track:after, :host([data-setel-widget]) .slick-track:after, setel-widget-reach-portal:not(#\#) .slick-track:after {
  clear: both;
}

:where(body[data-setel-page]) .slick-loading .slick-track, :host([data-setel-widget]) .slick-loading .slick-track, setel-widget-reach-portal:not(#\#) .slick-loading .slick-track {
  visibility: hidden;
}

:where(body[data-setel-page]) .slick-slide, :host([data-setel-widget]) .slick-slide, setel-widget-reach-portal:not(#\#) .slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

:where(body[data-setel-page]) [dir="rtl"] .slick-slide, :host([data-setel-widget]) [dir="rtl"] .slick-slide, setel-widget-reach-portal:not(#\#) [dir="rtl"] .slick-slide {
  float: right;
}

:where(body[data-setel-page]) .slick-slide img, :host([data-setel-widget]) .slick-slide img, setel-widget-reach-portal:not(#\#) .slick-slide img {
  display: block;
}

:where(body[data-setel-page]) .slick-slide.slick-loading img, :host([data-setel-widget]) .slick-slide.slick-loading img, setel-widget-reach-portal:not(#\#) .slick-slide.slick-loading img {
  display: none;
}

:where(body[data-setel-page]) .slick-slide.dragging img, :host([data-setel-widget]) .slick-slide.dragging img, setel-widget-reach-portal:not(#\#) .slick-slide.dragging img {
  pointer-events: none;
}

:where(body[data-setel-page]) .slick-initialized .slick-slide, :host([data-setel-widget]) .slick-initialized .slick-slide, setel-widget-reach-portal:not(#\#) .slick-initialized .slick-slide {
  display: block;
}

:where(body[data-setel-page]) .slick-loading .slick-slide, :host([data-setel-widget]) .slick-loading .slick-slide, setel-widget-reach-portal:not(#\#) .slick-loading .slick-slide {
  visibility: hidden;
}

:where(body[data-setel-page]) .slick-vertical .slick-slide, :host([data-setel-widget]) .slick-vertical .slick-slide, setel-widget-reach-portal:not(#\#) .slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

:where(body[data-setel-page]) .slick-arrow.slick-hidden, :host([data-setel-widget]) .slick-arrow.slick-hidden, setel-widget-reach-portal:not(#\#) .slick-arrow.slick-hidden {
  display: none;
}
